import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './shared/guard/auth.guard';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'sponsor-register', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'forgot-password', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },    
  { path: 'helpkids', loadChildren: () => import('./helpkids/helpkids.module').then(m => m.HelpkidsModule), 
  canMatch:[authGuard] },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
