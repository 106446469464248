import { CanMatchFn } from '@angular/router';
import { JwtService } from '../services/jwt.service';
import { inject } from '@angular/core';

export const authGuard: CanMatchFn = (route, segments) => {
  const jwtService: JwtService = inject(JwtService);
  if(jwtService.checkSessionTimeout()){
    return true;
  }
  else{
    jwtService.destroyToken();
    return false;
  }
};
